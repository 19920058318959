
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Grupo, Produto, UnidadeMedida } from '@/core/models/geral';
import { GrupoService, ProdutoService, UnidadeMedidaService } from '@/core/services/geral';

@Component
export default class CadastroProduto extends Vue{
    @Prop() private item!: Produto;
    @Prop() private value!: string;

    itemOriginal!: Produto;
    saveLoading: boolean = false;
    dialog: boolean = false;
    service: ProdutoService = new ProdutoService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    grupos: Grupo[] = [];
    grupoService: GrupoService = new GrupoService();

    unidadeMedidas: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.grupoService.ListarTudo().then(
            res => {
                this.grupos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.unidadeMedidaService.ListarTudo().then(
            res => {
                this.unidadeMedidas = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.saveLoading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.saveLoading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
