import { http, httpHeader } from "@/core/ApiConfig";

export class AutenticadorService {

    private _nomeControle = 'v1/autenticador';

    public async Autenticar(acesso: any){
        return await http.post(`${this._nomeControle}/usuario`, acesso);
    }

    public async ResetarUsuario(acesso: any){
        return await http.post(`${this._nomeControle}/resetarSenha`, acesso);
    }

}