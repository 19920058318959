import {http, httpHeader} from '../../ApiFinderConfig'

export class EntidadeLegalService {

    private _nomeControle: string = 'v1/entidadelegal';

    public ObterPorCnpj(cnpj: string){

      cnpj = cnpj.replace(/\D/g,'');
      
      return http.get(`${this._nomeControle}/juridica/${cnpj}`, httpHeader);
      
    }
}