import { Empresa, Grupo, UnidadeMedida } from ".";

export class Produto implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    nome: string = "";
    grupoId: number = 0;
    grupo!: Grupo;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    ativo: boolean = true;

    constructor(model?: Produto){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.ativo = model.ativo;
    }
}