import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class PageBase extends Vue {
    
    public sessionApp: any;
    public app: {empresaId: number, empresaLogo: string, usuarioId: number, nome: string, usuarioFoto: string, email: string, temaEscuro: boolean, login: string, perfilId: number, clienteId?: number} = {
        empresaId : 0,
        empresaLogo: '',
        usuarioId: 0,
        nome: '',
        usuarioFoto: '',
        email: '',
        temaEscuro: false,
        login: '',
        perfilId: 0,
        clienteId: undefined
    };

    constructor(){
        
        super();

        if (!localStorage.sessionApp){
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresaId = this.sessionApp.dados.empresaId;
        this.app.empresaLogo = this.sessionApp.dados.empresaLogo;
        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.nome = this.sessionApp.dados.nome;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.email = this.sessionApp.dados.email;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.login = this.sessionApp.dados.login;
        this.app.perfilId = this.sessionApp.dados.perfilId;
        this.app.clienteId = this.sessionApp.dados.clienteId;
    }

    ExportarExcel(tabela: string, worksheet?: string){
      TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }
}