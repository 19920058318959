import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PedidoService extends Service {

  constructor() {
    super('v1/pedido');
  }

  public GetByCliente(clienteId: number) {
    return http.get(`${this.GetNomeControle()}/ByCliente/${clienteId}`);
  }

  public Atender(id: number) {
    return http.post(`${this.GetNomeControle()}/${id}/Atender`, undefined);
  }

  public Receber(id: number) {
    return http.post(`${this.GetNomeControle()}/${id}/Receber`, undefined);
  }

  public Cancelar(id: number, motivo: string) {
    return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, undefined, {
      params: {
        motivo
      },
      headers: httpHeader.headers
    });
  }

  public RelatorioProduto(clienteId?: number, produtoId?: number, dataInicial?: string, dataFinal?: string) {
    return http.get(`${this.GetNomeControle()}/RelatorioProdutos`, {
      params: {
        clienteId,
        produtoId,
        dataInicial,
        dataFinal
      }
    });
  }

  public Relatorio(id?: number, clienteId?: number, dataInicio?: string, dataFim?: string) {
    return http.get(`${this.GetNomeControle()}/Relatorio`, {
      params: {
        id,
        clienteId,
        dataInicio,
        dataFim
      }
    });
  }

  private GetFiltro(parametros: string, filter: any) {
    if (filter) {
      let paramTemp = '';

      // if (filter.numero){
      //   if (paramTemp) { paramTemp += ' and '; }
      //   paramTemp += `numero eq ${filter.numero}`;
      // }

      if (filter.clienteId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `clienteId eq ${filter.clienteId}`;
      }

      if (filter.usuarioId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `solicitacao/usuarioId eq ${filter.usuarioId}`;
      }

      if (filter.dataInicio) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento ge ${filter.dataInicio}`;
      }

      if (filter.dataFim) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento le ${filter.dataFim}`;
      }

      // if (filter.dataDocumento){
      //   if (paramTemp) { paramTemp += ' and '; }
      //   paramTemp += `atendimento/dataHora ge ${filter.dataDocumento}T00:00:00Z and atendimento/dataHora le ${filter.dataDocumento}T23:59:59Z`;
      // }

      if (filter.dataEntrega) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataEntrega eq ${filter.dataEntrega}`;
      }

      if (filter.statusId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `statusId eq ${filter.statusId}`;
      }

      if (paramTemp) {
        if (parametros)
          parametros += '&';
        else
          parametros += '?';

        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
    parametros = this.GetFiltro(parametros, filter);
    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }
}