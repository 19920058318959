
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class MasterPage extends PageBase{
    @Prop() private divider!: string;
    @Prop() private color!: string;

    rota: any[] = [];

    mounted() {
        document.documentElement.style.setProperty('--color', this.color);

        let rota = this.$route.path.split("/");

        if(this.$route.path.split("/")[1] != 'home'){
            this.rota.unshift({text: "Home", href: '/home'});
            this.rota.push({text: rota[rota.length - 1].charAt(0).toUpperCase() + rota[rota.length - 1].slice(1), href: this.$route.path})
        }
        else{
            this.rota.unshift({text: "Home", href: '/home'});
        }
    }
}
