import { ClienteProduto, Empresa } from '.';
import { PessoaJuridica } from './../shared/Pessoa';

export class Cliente extends PessoaJuridica{
    
    empresaId: number = 0;
    produtos: ClienteProduto[] = [];
    logo: any = null;

    constructor(model?: Cliente){

        super(model);
        
        if(!model)
            return;

        this.empresaId = model.empresaId;
        this.produtos = model.produtos;
        this.logo = model.logo;
    }
}