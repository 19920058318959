
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class ListaHeader extends PageBase{
    @Prop() private value!: string;
    @Prop() private item!: any;

    dialog: boolean = false;
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    lista: any[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    mounted(){
        this.item.forEach(x => {
            if(x.text.length > 0){
                this.lista.push(x);
            }
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Close(){
        this.dialog = false;
    }
}
