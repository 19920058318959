
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Cliente, ClienteProduto, Estado, Municipio } from '@/core/models/geral';
import { ClienteService } from '@/core/services/geral';
import { EnderecoService, EntidadeLegalService } from '@/core/services/finder';
import { ArquivoService } from '@/core/services/shared';

@Component
export default class CadastroCliente extends Vue{
    @Prop() item!: Cliente;
    @Prop() value!: string;

    overlay: boolean = false;
    itemOriginal!: Cliente;
    saveLoading: boolean = false;
    dialog: boolean = false;
    service: ClienteService = new ClienteService();
    valid: boolean = true;
    tabActive: any = {};
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    mskCelular = process.env.VUE_APP_MSK_CELULAR;
    mskCep = process.env.VUE_APP_MSK_CEP;

    estados: Estado[] = [];
    municipios: Municipio[] = [];
    enderecoService: EnderecoService = new EnderecoService();
    entidadeLegalService: EntidadeLegalService = new EntidadeLegalService();

    dialogCadastroProduto: boolean = false;
    editarProduto: boolean = false;
    produto: ClienteProduto = new ClienteProduto();
    headerProduto: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nome', value: 'produto.nome' },
        { text: 'Grupo', value: 'produto.grupo.nome' },
        { text: 'U. Medida', value: 'produto.unidadeMedida.sigla' },
        { text: 'Valor', value: 'valor' }
    ];

    logo: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("item.endereco.municipio.estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    async LoadImage(){
        this.logo = this.$refs.file.files[0];
        if (!this.logo)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.logo);
        this.item.logo = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        this.item.logo = null;
        this.logo = null;
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    CarregarMunicipio(estadoId: number){
        this.enderecoService.ObterMunicipios(estadoId).then(
            res => {
                this.municipios = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    ObterEndereco(cep: string){
        this.enderecoService.ObterPorCep(cep).then(
            res => {
                const endereco = res.data;
                if (endereco) {
                    this.item.endereco.logradouro = endereco.logradouro;
                    this.item.endereco.bairro = endereco.bairro;
                    this.item.endereco.municipio.estadoId = endereco.estadoId;
                    this.item.endereco.municipioId = endereco.municipioId;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    ObterEntidadeLegal(cnpj: string){
        this.overlay = true;
        this.entidadeLegalService.ObterPorCnpj(cnpj).then(
            res => {
                const entidade = res.data;
                if (entidade) {
                    this.item.nome = entidade.nome;
                    this.item.nomeFantasia = entidade.nomeFantasia;
                    this.item.endereco.cep = entidade.endereco.cep;
                    this.item.endereco.logradouro = entidade.endereco.logradouro;
                    this.item.endereco.numero = entidade.endereco.numero;
                    this.item.endereco.complemento = entidade.endereco.complemento;
                    this.item.endereco.bairro = entidade.endereco.bairro;
                    this.item.endereco.municipio.estadoId = entidade.endereco.estadoId;
                    this.item.endereco.municipioId = entidade.endereco.municipioId;
                    this.item.email = entidade.email;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        });
    }

    CadastroProduto(item?: ClienteProduto){
        if(item){
            this.produto = item;
            this.editarProduto = true;
        }
        else{
            this.produto = new ClienteProduto();
            this.editarProduto = false;
        }
        this.dialogCadastroProduto = true;
    }

    ExcluirProduto(item: ClienteProduto){        
        var teste = this;
        const excluir = function() {
            return new Promise( async function (resolve, reject){
                var index = teste.item.produtos.indexOf(item);
                teste.item.produtos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarProduto(){
        if(!this.editarProduto){
            this.item.produtos.push(this.produto);
        }
        this.dialogCadastroProduto = false;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.saveLoading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.saveLoading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
