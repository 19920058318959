
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { ClienteProduto, Produto } from '@/core/models/geral';
import { ProdutoService } from '@/core/services/geral';

@Component
export default class CadastroClienteProduto extends Vue{
    @Prop() private item!: ClienteProduto;
    @Prop() private value!: string;
    @Prop({default: false}) private editar!: boolean;

    valid: boolean = true;
    dialog: boolean = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    produtoService: ProdutoService = new ProdutoService();
    produtos: Produto[] = [];

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    mounted() {
        
    }

    Carregar(){
        this.produtoService.ListarTudoFiltro("ativo", "nome", "Grupo, UnidadeMedida").then(
            res => {
                this.produtos = res.data.items;
            }
        )
    }

    Close(){
        this.dialog = false;
        this.$emit("fechou");
    }

    Salvar(){
        this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
        
        this.Close();
        this.$emit("salvou");
    }
}
