
import { PageBase } from '@/core/models/shared';
import { UsuarioService } from '@/core/services/geral';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class AlterarSenha extends PageBase{
    @Prop() private value!: string;

    dialog: boolean = false;
    service: UsuarioService = new UsuarioService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    senhaAntiga: string = '';
    senhaNova: string = '';
    repetirSenha: string = '';

    show1: boolean = false;
    show2: boolean = false;
    show3: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {

    }

    AlterarSenha(){

        if (this.$refs.form.validate()) {
            this.service.AlterarSenha(this.app.usuarioId, this.senhaAntiga, this.senhaNova).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.Close();
            })
        }
    }

    Reset(){
        this.repetirSenha = "";
        this.senhaAntiga = "";
        this.senhaNova = "";
        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.$refs.form.resetValidation();
    }

    Close(){
        this.Reset();
        this.dialog = false;
    }
}
