
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Cliente } from '@/core/models/geral';
import { ClienteService } from '@/core/services/geral';
import { Pedido, PedidoProduto } from '@/core/models/operacao';
import { PedidoService } from '@/core/services/operacao';
import { PageBase } from '@/core/models/shared';

@Component
export default class CadastroPedido extends PageBase{
    @Prop() public item!: Pedido;
    @Prop() private value!: string;
    @Prop() public operacaoId!: number;

    itemOriginal!: Pedido;
    saveLoading: boolean = false;
    dialog: boolean = false;
    service: PedidoService = new PedidoService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    clientes: Cliente[] = [];
    clienteService: ClienteService = new ClienteService();
    isClienteLoading: boolean = false;
    onSearchCliente: any = null;

    dialogCadastroProduto: boolean = false;
    produto: PedidoProduto = new PedidoProduto();
    editarProduto: boolean = false;
    headerProduto: any[] = [];

    @Watch('operacaoId')
    WatchOperacao(){
        switch(this.operacaoId){
            case 1:
                this.headerProduto = [
                    { text: '', value: 'actions', sortable: false },
                    { text: 'Nome', value: 'produto.nomeGrupoUnidadeMedida' },
                    { text: 'Quantidade (Solicitada)', value: 'quantidadeSolicitada' },
                    { text: 'Observação', value: 'observacao' }
                ];
                break;
            case 2:
                this.headerProduto = [
                    { text: '', value: 'actions', sortable: false },
                    { text: 'Nome', value: 'produto.nomeGrupoUnidadeMedida' },
                    { text: 'Quantidade (Solicitada)', value: 'quantidadeSolicitada' },
                    { text: 'Observação', value: 'observacao' },
                    { text: 'Quantidade (Enviada)', value: 'quantidadeEnviada' }
                ];
                break;
            case 3:
                this.headerProduto = [
                    { text: '', value: 'actions', sortable: false },
                    { text: 'Nome', value: 'produto.nomeGrupoUnidadeMedida' },
                    { text: 'Quantidade (Solicitada)', value: 'quantidadeSolicitada' },
                    { text: 'Quantidade (Enviada)', value: 'quantidadeEnviada' },
                    { text: 'Observação', value: 'observacao' },
                    { text: 'Quantidade (Recebida)', value: 'quantidadeRecebida' }
                ];
                break;
        }
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
            this.WatchOperacao();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.clientes.push(this.item.cliente);

            switch(this.operacaoId){
                case 2:
                    this.item.produtos.forEach(produto => {
                        produto.quantidadeEnviada = produto.quantidadeSolicitada;
                    });
                    break;
                case 3:
                    this.item.produtos.forEach(produto => {
                        produto.quantidadeRecebida = produto.quantidadeEnviada;
                    });
                    break;
            }

        }
        else if (this.app.clienteId && this.item.clienteId == 0){
            this.clienteService.ObterPorId(this.app.clienteId).then(res => {
                this.clientes.push(res.data);
                this.item.clienteId = this.app.clienteId!;                
            });
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        const clienteService = new ClienteService();
        clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        
    }

    CadastroProduto(item?: PedidoProduto){
        if(item){
            this.produto = item;
            this.editarProduto = true;
        }
        else{
            this.produto = new PedidoProduto();
            this.editarProduto = false;
        }
        this.dialogCadastroProduto = true;
    }

    ExcluirProduto(item: PedidoProduto){        
        var teste = this;
        const excluir = function() {
            return new Promise( async function (resolve, reject){
                var index = teste.item.produtos.indexOf(item);
                teste.item.produtos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarProduto(){
        if(!this.editarProduto){
            this.item.produtos.push(this.produto);
        }
        this.dialogCadastroProduto = false;
    }

    async Salvar(){

        if (this.$refs.form.validate()) {
            const contexto = this;
            switch(this.operacaoId){
                case 1:
                    let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                    this.saveLoading = true;
                    (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                        res => {
                            AlertSimpleRes("Aviso", res);
                            this.$emit("salvou");
                            this.Close();
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    ).finally(() => {
                        this.saveLoading = false;
                    })
                    break;
                case 2:
                    const atender = function() {
                        let patchModel = jiff.diff(contexto.itemOriginal, contexto.item, false);
                        contexto.saveLoading = true;

                        contexto.service.Atender(contexto.item.id).then(
                            () => {
                                (contexto.item.id > 0 ? contexto.service.Salvar(patchModel, contexto.item.id) : contexto.service.Salvar(contexto.item)).then(
                                    res => {
                                        AlertSimpleRes("Aviso", res);
                                        contexto.$emit("salvou");
                                        contexto.Close();
                                    },
                                    err => AlertSimpleErr("Aviso!", err)
                                );
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        ).finally(() => {
                            contexto.saveLoading = false;
                        });
                    }

                    AlertQuestion("Atenção!", "Deseja realmente atender o registro atual?" , atender);
                    break;
                case 3:
                    const receber = function() {
                        let patchModel = jiff.diff(contexto.itemOriginal, contexto.item, false);
                        contexto.saveLoading = true;

                        contexto.service.Receber(contexto.item.id).then(
                            () => {
                                (contexto.item.id > 0 ? contexto.service.Salvar(patchModel, contexto.item.id) : contexto.service.Salvar(contexto.item)).then(
                                    res => {
                                        AlertSimpleRes("Aviso", res);
                                        contexto.$emit("salvou");
                                        contexto.Close();
                                    },
                                    err => AlertSimpleErr("Aviso!", err)
                                )
                            },
                            err => AlertSimpleErr("Aviso!", err)
                        ).finally(() => {
                            contexto.saveLoading = false;
                        });

                    }

                    AlertQuestion("Atenção!", "Deseja realmente receber o registro atual?" , receber);
                    break;
            }
        }
    }

    Close(){
        this.dialog = false;
    }
}
