import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const RequireAuth = (to: any, next: NavigationGuardNext<Vue>, perfilPermitido: number[]) => {
  var sessionApp: any;
  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);
    if(perfilPermitido.find(x => x == sessionApp.dados.perfilId)){
      next();
    }
    else{
      next({ name:"Home" });
    }
  }
  else{
    next({ name: '' });
  }
};

const routes: Array<RouteConfig> = [
  // GERAL
  { path: '/', redirect: '/home' },

  { path: '/login', name: 'Login', component: () => import('../views/shared/Login.vue') },
  { path: '/recuperarSenha/:token', name: 'LoginToken', component: () => import('../views/shared/RecuperarSenha.vue') },
  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue') },
  { path: '/geral/cliente', name: 'ListarCliente', component: () => import('../views/geral/cliente/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  { path: '/geral/grupo', name: 'ListarGrupo', component: () => import('../views/geral/grupo/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  { path: '/geral/unidademedida', name: 'ListarUnidadeMedida', component: () => import('../views/geral/unidadeMedida/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  { path: '/geral/produto', name: 'ListarProduto', component: () => import('../views/geral/produto/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  // OPERACAO
  { path: '/operacao/pedido', name: 'ListarPedido', component: () => import('../views/operacao/pedido/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,2,3]) },
  { path: '/operacao/pedido/detalhar/:id', name: 'DetalharPedido', component: () => import('../views/operacao/pedido/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,2,3]) },
  // RELATORIOS
  { path: '/relatorio/medicao', name: 'ConfiguracaoPedido', component: () => import('../views/relatorio/pedido/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  { path: '/relatorio/medicao/detalhar', name: 'RelatorioPedido', component: () => import('../views/relatorio/pedido/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },  
  { path: '/relatorio/produto', name: 'ConfiguracaoProduto', component: () => import('../views/relatorio/produto/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  { path: '/relatorio/produto/detalhar', name: 'RelatorioProduto', component: () => import('../views/relatorio/produto/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
  // CONFIGURACAO
  { path: '/configuracao/usuario', name: 'ListarUsuario', component: () => import('../views/geral/usuario/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1,3]) },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
