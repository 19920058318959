import { Produto } from "../geral";
import { Pedido } from "./Pedido";

export class PedidoProduto implements Shared.IEntity{

    id: number = 0;
    pedidoId: number = 0;
    pedido!: Pedido;
    produtoId: number = 0;
    produto!: Produto;
    valor: number = 0;
    quantidadeSolicitada: number = 0;
    quantidadeEnviada: number = 0;
    quantidadeRecebida: number = 0;
    observacao: string = "";

    alterado: boolean = false;

    constructor(model?: PedidoProduto){

        if(!model)
            return;

        this.id = model.id;
        this.pedidoId = model.pedidoId;
        this.pedido = model.pedido;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.valor = model.valor;
        this.quantidadeSolicitada = model.quantidadeSolicitada;
        this.quantidadeRecebida = model.quantidadeRecebida;
        this.quantidadeRecebida = model.quantidadeRecebida;
    }
}