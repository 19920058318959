import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme:{
    dark: false,
    default: 'light',
    options: {
      customProperties: true
    },
    themes:{
      light:{
        menuActive: colors.blueGrey.lighten5,
        cadastroBackground: '#eff2f5',
        chipGreen: colors.green.lighten5,
        chipFontGreen: colors.green.lighten2,
        chipRed: colors.red.lighten5,
        chipFontRed: colors.red.lighten2,
        chipBlue: colors.blue.lighten5,
        chipFontBlue: colors.blue.lighten2,
        chipYellow: colors.yellow.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      },
      dark:{
        menuActive: colors.blueGrey.lighten5,
        cadastroBackground: '#222222',
        chipGreen: colors.green.lighten3,
        chipFontGreen: colors.green.darken2,
        chipRed: colors.red.lighten3,
        chipFontRed: colors.red,
        chipBlue: colors.blue.lighten3,
        chipFontBlue: colors.blue.accent2,
        chipYellow: colors.amber.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      }
    }
  }
});
