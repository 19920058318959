
import { PageBase } from '@/core/models/shared';
import { UsuarioService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class MasterPage extends PageBase{
  @Prop() public icone!: string;
  @Prop() public titulo!: string;

  drawer: boolean = false;
  dialogAlterarSenha: boolean = false;

  geralMenuButtons: any[] = [];
  operacaoMenuButtons: any[] = [];
  relatorioMenuButtons: any[] = [];
  configMenuButtons: any[] = [];

  created() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
    this.CarregarMenu();
  }

  CarregarMenu(){
    this.geralMenuButtons = [
      {name:'Cliente', icon:'mdi-account', path:'/geral/cliente', restrito: this.UsuarioRestrito([1,3])},
      {name:'Grupo', icon:'mdi-basket', path:'/geral/grupo', restrito: this.UsuarioRestrito([1,3])},
      {name:'Unidade de Medida', icon:'mdi-pencil-ruler', path:'/geral/unidademedida', restrito: this.UsuarioRestrito([1,3])},
      {name:'Produto', icon:'mdi-carrot', path:'/geral/produto', restrito: this.UsuarioRestrito([1,3])}
    ];
    this.operacaoMenuButtons = [
      {name:'Pedido', icon:'mdi-clipboard-list', path:'/operacao/pedido', restrito: this.UsuarioRestrito([1,2,3])},
    ];
    this.relatorioMenuButtons = [
      {name:'Boletim de Medição', icon:'mdi-clipboard-list', path:'/relatorio/medicao', restrito: this.UsuarioRestrito([1,3])},
      {name:'Boletim de Medição (Produto)', icon:'mdi-clipboard-list', path:'/relatorio/produto', restrito: this.UsuarioRestrito([1,3])},
    ];
    this.configMenuButtons = [
      {name:'Usuário', icon:'mdi-account', path:'/configuracao/usuario', restrito: this.UsuarioRestrito([1,3])},
    ];
  }

  GetAppVersion(){
    const pack = require('../../../package.json');
    return pack.version;
  }

  TemaEscuro(){
    const usuarioService = new UsuarioService();
      usuarioService.MudarTema(this.app.usuarioId).then(
        res => super.MudarTema(res.data),
        err => AlertSimpleErr("Aviso!", err)
      );
  }

  UsuarioRestrito(perilIds: any = []){
    if(perilIds.length > 0)
    {
      if(perilIds.find(x => x == this.app.perfilId))
        return false;
      else   
        return true;
    }
    else{
      return false;
    }
  }

  Logout() {
    localStorage.clear();
    this.$vuetify.theme.dark = false;
    this.$router.push({name: 'Login'});
  }

}
