import { PessoaJuridica } from './../shared/Pessoa';

export class Empresa extends PessoaJuridica{

    dominio: string = '';
    logo: any = null;

    constructor(model?: Empresa){
        
        super(model);
        if(!model)
            return;

        this.dominio = model?.dominio;
        this.logo = model.logo;
    }

}