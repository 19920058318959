import { Cliente, Produto } from ".";

export class ClienteProduto {
    
    clienteId: number = 0;
    cliente!: Cliente;
    produtoId: number = 0;
    produto!: Produto;
    valor: number = 0;
    observacao: string = "";

    constructor(model?: ClienteProduto){
        
        if(!model)
            return;

        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.valor = model.valor;
        this.observacao = model.observacao;
    }
}