import { Cliente, Empresa, PerfilUsuario } from ".";
import { Complementar } from "../shared";

export class Usuario implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    clienteId?: number;
    cliente!: Cliente;
    login: string = "";
    senha: string = "";
    dataHoraCriacao?: string;
    foto: any = null;
    assinatura: any = null;
    perfilId: number = 1;
    perfil!: PerfilUsuario;
    token: string = "";
    complementar: Complementar = new Complementar();
    primeiroAcesso: boolean = true;
    ativo: boolean = true;
    temaEscuro: boolean = false;

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.login = model.login;
        this.senha = model.senha;
        this.dataHoraCriacao = model.dataHoraCriacao;
        this.foto = model.foto;
        this.assinatura = model.assinatura;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.token = model.token;
        this.complementar = model.complementar;
        this.primeiroAcesso = model.primeiroAcesso;
        this.ativo = model.ativo;
        this.temaEscuro = model.temaEscuro;
    }
}