import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ProdutoService extends Service{
    
    constructor(){
        super('v1/produto');
    }

    public ByCliente(clienteId: number) {
        return http.get(`${this.GetNomeControle()}/ByCliente/${clienteId}`);
    }
}