import Vue from 'vue';

import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';

import CadastroCliente from './geral/cliente/Cadastro.vue';
import CadastroClienteProduto from './geral/cliente/CadastroProduto.vue';

import CadastroGrupo from './geral/grupo/Cadastro.vue';
import CadastroProduto from './geral/produto/Cadastro.vue';
import CadastroUnidadeMedida from './geral/unidadeMedida/Cadastro.vue';
import CadastroUsuario from './geral/usuario/Cadastro.vue';

import CadastroPedido from './operacao/pedido/Cadastro.vue';
import CadastroPedidoProduto from './operacao/pedido/CadastroProduto.vue';

Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);

Vue.component("cadastro-cliente", CadastroCliente);
Vue.component("cadastro-clienteProduto", CadastroClienteProduto);

Vue.component("cadastro-grupo", CadastroGrupo);
Vue.component("cadastro-produto", CadastroProduto);
Vue.component("cadastro-unidadeMedida", CadastroUnidadeMedida);
Vue.component("cadastro-usuario", CadastroUsuario);

Vue.component("cadastro-pedido", CadastroPedido);
Vue.component("cadastro-pedidoProduto", CadastroPedidoProduto);
