import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class EmpresaService extends Service{
    
    constructor(){
        super('v1/empresa');
    }

    public ObterLogo(){
        return http.get(`${this.GetNomeControle()}/logo`);
    }
}